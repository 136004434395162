<template>
  <v-container fluid class="primary">
    <v-row no-gutters style="height: 100vh" justify="center">
      <v-col md="4" align-self="center">
        <v-card class="pa-2" outlined tile>
          <img
            src="@/assets/images/RTB_Logo_Stacked-Blue.png"
            alt=""
            style="width: 90%"
            class="mx-3"
          />
          <v-card-title>Password Reset</v-card-title>
          <v-card-subtitle
            >Please enter a new password to continue</v-card-subtitle
          >
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              Username
              <v-text-field v-model="username" disabled></v-text-field>

              New Password
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :rules="[rules.required, rules.min]"
                required
              ></v-text-field>

              Confirm New Password
              <v-text-field
                v-model="confirmPassword"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :rules="[
                  !!confirmPassword || 'type confirm password',
                  password === confirmPassword ||
                    'The password confirmation does not match.'
                ]"
                required
              ></v-text-field>

              <p>
                <em
                  >For help logging into <strong>RTB Connect</strong>, please
                  email
                  <a href="mailto:RTBconnect@relaxtheback.com"
                    >RTBconnect@relaxtheback.com</a
                  >
                  for assistance.</em
                >
              </p>
            </v-form>
          </v-card-text>

          <v-card-actions v-if="showForgotPassword === false">
            <v-btn
              color="primary"
              block
              class="px-4 ml-2"
              @click="resetPassword"
            >
              Change Password
            </v-btn>

            <!-- <v-btn text> Forgot Password? </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios").default;
axios.defaults.withCredentials = true;
import helpers from "@/plugins/helpers";
import moment from "moment";
export default {
  data: () => ({
    valid: true,
    showForgotPassword: false,
    showPassword: false,
    // username: "justin@bamboodev.com",
    // password: "topsecret123",
    // username: "",
    password: "",
    confirmPassword: "",
    usernameRules: [
      v => !!v || "Username is required",
      v => /.+@.+\..+/.test(v) || "Username must be a valid email address"
    ],
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`
    }
    // users: this.$store.state.users,
  }),
  computed: {
    username() {
      return this.$route.params.username;
    },
    hash() {
      return this.$route.params.hash;
    }
    // token() {
    //   return this.$store.state.user.token;
    // },
    // users() {
    //   return this.$store.state.users;
    // },
    // notificationDate() {
    //   return this.$store.state.notificationDate;
    // }
  },
  methods: {
    sendPasswordResetLink() {
      let vm = this;
      if (this.$refs.form.validate()) {
        axios
          .post(vm.$root.urls.api + "/user/reqestPasswordResetLink", {
            username: vm.username
          })
          .then(function(results) {
            // tell user that link has been sent
            vm.$toasted
              .show(
                "Thanks! Please check your email for a password reset link",
                {
                  type: "success",
                  icon: "mdi-thumb-up"
                }
              )
              .goAway(2500);
            // reset login form
            vm.showForgotPassword = false;
          });
      }
    },
    resetPassword() {
      // if form is valid, submit username, password and hash to api for verification
      let vm = this;
      if (this.$refs.form.validate()) {
        axios
          .post(vm.$root.urls.api + "/user/resetPassword", {
            username: vm.username,
            password: vm.password,
            hash: vm.hash
          })
          .then(function(results) {
            vm.$toasted.show(
              "Thanks! Your password has been changed. You may now login.",
              {
                type: "success",
                icon: "mdi-thumb-up",
                action: {
                  text: "Login",
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                    vm.$router.push("/");
                  }
                }
              }
            );
          });
      }
    }
  }
};
</script>

<style lang="scss">
.version {
  font-size: 10px;
  color: gray;
}
</style>
