<template>
  <v-container fluid class="primary">
    <v-row no-gutters style="height: 100vh" justify="center">
      <v-col md="4" align-self="center">
        <v-card class="pa-2" outlined tile>
          <img
            src="@/assets/images/RTB_Logo_Stacked-Blue.png"
            alt=""
            style="width: 90%"
            class="mx-3"
          />
          <v-card-title>Please log in to continue</v-card-title>
          <v-card-subtitle
            >Not yet registered? Please see your office
            administrator.</v-card-subtitle
          >
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              Username
              <v-text-field
                v-model="username"
                :rules="usernameRules"
                required
              ></v-text-field>
              <div v-if="showForgotPassword === false">
                Password
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  @keyup.enter="login"
                  :rules="[rules.required, rules.min]"
                  required
                ></v-text-field>

                <p>
                  <em
                    >For help logging into <strong>RTB Connect</strong>, please
                    email
                    <a href="mailto:RTBconnect@relaxtheback.com"
                      >RTBconnect@relaxtheback.com</a
                    >
                    for assistance.</em
                  >
                </p>
              </div>
            </v-form>
          </v-card-text>

          <v-card-actions v-if="showForgotPassword === false">
            <v-btn color="primary" class="px-4 ml-2" @click="login">
              Login
            </v-btn>
            <v-btn
              color="primary"
              text
              class="px-4 ml-2"
              @click="showForgotPassword = true"
            >
              Forgot Password?
            </v-btn>
            <span class="version ml-2">v8.24</span>
          </v-card-actions>
          <v-card-actions v-if="showForgotPassword === true">
            <v-btn
              color="primary"
              block
              class="px-4 "
              @click="sendPasswordResetLink"
            >
              Send Password Reset Link
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios").default;
axios.defaults.withCredentials = true;
import helpers from "@/plugins/helpers";
import moment from "moment";
export default {
  data: () => ({
    valid: true,
    showForgotPassword: false,
    showPassword: false,
    username: "",
    password: "",
    usernameRules: [
      v => !!v || "Username is required",
      v => /.+@.+\..+/.test(v) || "Username must be a valid email address"
    ],
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`
    }
    // users: this.$store.state.users,
  }),
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    users() {
      return this.$store.state.users;
    },
    notificationDate() {
      return this.$store.state.notificationDate;
    },
    redirectAnnouncements() {
      return this.$store.state.redirectAnnouncements;
    },
    snapshotid() {
      return this.$store.state.snapshotid;
    }
  },
  methods: {
    login() {
      let vm = this;
      if (this.$refs.form.validate()) {
        axios
          .post(vm.$root.urls.api + "/user/login", {
            username: vm.username,
            password: vm.password
          })
          .then(function(results) {
            // console.log(results.data);
            if (results.data.user) {
              let user = results.data.user;
              let token = results.data.token;
              // dark mode
              if (results.data.user.darkMode === "true") {
                vm.$vuetify.theme.dark = true;
              }
              vm.$store.commit("login", {
                username: user.username,
                role: user.role,
                firstname: user.firstname,
                lastname: user.lastname,
                notification: user.notification,
                phone: user.phone,
                darkMode: user.darkMode,
                unread: user.unread,
                token
              });
              localStorage.setItem("rtbjwt", token);

              // update unread notifications counter
              vm.$store.commit("updateNotification", {
                notifications: user.unread.length
              });

              // log the login activity
              vm.logLogin();

              // load CMS content
              vm.getCms();

              // load Bulletins
              vm.getBulletins();

              // load documents via API
              vm.getDocuments();

              // // redirect as needed
              // vm.redirect();
            } else {
              // console.log("invalid login");
              // alert("Invalid login... please try again");
              vm.$toasted
                .show("Invalid login... please try again", {
                  type: "error",
                  icon: "mdi-alert-circle"
                })
                .goAway(2500);
              vm.valid = false;
            }
          })
          .catch(function(error) {
            // handle error
            console.log(error);
            // alert("Invalid login... please try again");
            vm.$toasted
              .show("Invalid login... please try again", {
                type: "error",
                icon: "mdi-alert-circle"
              })
              .goAway(2500);
            vm.valid = false;
          });
      }
    },
    getDocuments() {
      let vm = this;
      // load mock data
      // this.rawData = rawData;
      // let docs = this.rawData;

      // load documents via API
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios.get(vm.$root.urls.api + "/documents", {}).then(function(results) {
        // store in application state
        vm.$store.commit("saveDocuments", {
          documents: results.data
        });

        // redirect as needed
        vm.redirect();

        // // did the user try to view announcements? If so, redirect to announcements (bulletins)
        // if (vm.redirectAnnouncements) {
        //   vm.$router.push("bulletins");
        // } else if (vm.notificationDate) {
        //   // redirect to notifications
        //   vm.$router.push("notifications");
        // } else {
        //   // redirect to dash
        //   vm.$router.push("dashboard");
        // }
      });
    },
    redirect() {
      let vm = this;

      // DEBUG

      console.log(`Documents: ${vm.$store.state.documents.length}`);

      // did the user try to view announcements? If so, redirect to announcements (bulletins)
      if (vm.redirectAnnouncements) {
        vm.$router.push("bulletins");
      } else if (vm.notificationDate) {
        // redirect to notifications
        vm.$router.push("notifications");
      } else if (vm.snapshotid) {
        // redirect to snapshot
        vm.$router.push(`snapshots/${vm.snapshotid}`);
      } else {
        // redirect to dash
        vm.$router.push("dashboard");
      }
    },
    getCms() {
      let vm = this;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios.get(vm.$root.urls.api + "/cms", {}).then(function(results) {
        // store in application state
        vm.$store.commit("updateDashboard", {
          cms: results.data
        });
      });
    },
    getBulletins() {
      let vm = this;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios.get(vm.$root.urls.api + "/bulletin", {}).then(function(results) {
        // store in application state
        vm.$store.commit("updateBulletins", {
          bulletins: results.data
        });
      });
    },
    getNotifications() {
      let vm = this;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios.get(vm.$root.urls.api + "/user", {}).then(function(results) {
        // update unread notifications counter
        vm.$store.commit("updateNotification", {
          notifications: results.data[0].unread.length
        });
      });
    },
    logLogin() {
      let vm = this;
      let activity = {
        user: {
          username: `${this.$store.state.user.firstname} ${this.$store.state.user.lastname}`,
          email: this.$store.state.user.username
        },
        date: moment().format("MM/DD/YYYY"),
        action: `Logged in`
      };
      helpers.log(vm.token, vm.$root.urls.api, activity);
      // vm.$store.commit("addActivity", {
      //   activity,
      // });
    },
    sendPasswordResetLink() {
      let vm = this;
      if (this.$refs.form.validate()) {
        axios
          .post(vm.$root.urls.api + "/user/reqestPasswordResetLink", {
            username: vm.username
          })
          .then(function(results) {
            // tell user that link has been sent
            vm.$toasted
              .show(
                "Thanks! Please check your email for a password reset link",
                {
                  type: "success",
                  icon: "mdi-thumb-up"
                }
              )
              .goAway(2500);
            // reset login form
            vm.showForgotPassword = false;
          })
          .catch(function(error) {
            vm.$toasted
              .show("Sorry, username not found... ", {
                type: "error",
                icon: "mdi-alert-circle"
              })
              .goAway(2500);
          });
      }
    },
    checkToken() {
      let vm = this;
      // check to see if token is kept in local storage
      let token = localStorage.getItem("rtbjwt");

      if (token) {
        // parse token for user info
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function(c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        let user = JSON.parse(jsonPayload);
        if (user.role) {
          this.$store.commit("login", {
            username: user.username,
            role: user.role,
            firstname: user.firstname,
            lastname: user.lastname,
            notification: user.notification,
            phone: user.phone,
            darkMode: user.darkMode,
            unread: user.unread,
            token
          });

          // dark mode
          if (user.darkMode === "true") {
            vm.$vuetify.theme.dark = true;
          }
          // log the login activity
          vm.logLogin();

          // load CMS content
          vm.getCms();

          // load Bulletins
          vm.getBulletins();

          // load Notifications
          vm.getNotifications();

          // load documents via API
          vm.getDocuments();

          // // redirect as needed
          // vm.redirect();
        } else {
          vm.$toasted
            .show("Invalid login... please try again", {
              type: "error",
              icon: "mdi-alert-circle"
            })
            .goAway(2500);
        }
      }
    }
  },
  created: function() {
    this.checkToken();
  },
  mounted() {
    if(process.env.VUE_APP_UNAME) this.username = process.env.VUE_APP_UNAME;
    if(process.env.VUE_APP_PWRD) this.password = process.env.VUE_APP_PWRD;
  }
};
</script>

<style lang="scss">
.version {
  font-size: 10px;
  color: gray;
}
</style>
